<template>
  <div>
    <div class="air__utils__heading">
      <div class="row">
        <div class="col-md-9">
          <h5>
            <span class="mr-3">Daftar Pengunjung</span>
          </h5>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex flex-column justify-content-center mr-auto">
              <h5 class="mb-0">Semua Pengunjung</h5>
            </div>
          </div>
          <div class="card-body">
            <div class="air__utils__scrollTable">
              <a-table
                  :columns="columns"
                  :row-key="record => record.id"
                  :data-source="data"
                  :pagination="pagination"
                  :loading="loading"
                  @change="handleTableChange"
              >
                <span slot="full_name" slot-scope="full_name">
                  <p v-if="full_name">{{full_name}}</p>
                  <p v-else> - </p>
                </span>
                <span slot="time" slot-scope="time">
                  <p v-if="time">{{time}}</p>
                  <p v-else> - </p>
                </span>
                <span slot="location" slot-scope="location">
                  <p v-if="location">
                    <a @click="funcMap(location)">
                      {{ location.lat + "," + location.long }}
                    </a>
                  </p>
                  <p v-else> - </p>
                </span>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {getDetailViewer} from "@/api/publicContent.api";

const columns = [
  {
    title: "Nama",
    dataIndex: "user.fullname",
    scopedSlots: {customRender: 'full_name'}
  },
  {
    title: "Waktu",
    dataIndex: "viewed_at",
    scopedSlots: {customRender: 'time'}
  },
  {
    title: "Lokasi",
    dataIndex: "viewed_from",
    scopedSlots: {customRender: 'location'}
  },
];
import {mapMutations} from "vuex"

export default {
  props: {
    id: String
  },
  data() {
    return {
      data: [],
      pagination: {
        current: 1,
        pageSize: 10
      },
      loading: false,
      columns,
      map: 'https://www.google.com/maps/?q=',
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapMutations(['UPDATE_DATA_ACCOUNT']),
    handleTableChange(pagination) {
      const pager = {...this.pagination};
      pager.current = pagination.current;

      this.pagination = pager;
      this.fetchData({
        page: pager.current,
        limit: pager.pageSize,
      });
    },
    async fetchData() {
      try {
        this.loading = true;
        let response = await getDetailViewer(this.id);
        if (response.status == 200) {
          this.data = response.data.results;
          // this.UPDATE_DATA_VIEWER(this.data)
          const pagination = { ...this.pagination };
          pagination.total = response.data.paging.total;
          this.loading = false;
          this.pagination = pagination;
        }
      } catch (error) {/**/}
    },
    funcMap(e) {
      window.open((this.map+ e.lat+ ',' + e.long), '_blank')
    },
  }
};
</script>
